import { NavLink, useNavigate, useParams } from "react-router-dom";
import FastImage from "../../../globals/elements/fastimg";
import { loadScript, route } from "../../../globals/constants";
import { Fragment, useEffect, useState } from "react";
import SectionBanner from "../../sections/common/banner/banner";
import { useTranslation } from "react-i18next";
import SwiperWrapper from "../SwiperWrapper";
import { SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../globals/elements/loader";
import { productFetch } from "../../../store/singleProduct";
import { categoryFetch } from "../../../store/singleCategoryProductsSlice";
import { getProduct, getProducts } from "../../../api/productsApi";
import { getCategory } from "../../../api/categoriesApi";
import { addQty, addToCart, removeItem } from "../../../store/cartSlice";
import toast, { Toaster } from "react-hot-toast";
import ProductDescription from "../ProductDescription";
import HelmetComponent from "../HelmetComponent";

function ShopProductDetailPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [product, setProduct] = useState({});
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [products, setProducts] = useState([]);
  const [details, setDetails] = useState({});
  // const details = {};
  const [category, setCategory] = useState({});
  const [count, setCount] = useState(1);

  const { lang, productId, categoryId } = useParams();

  const locale = lang;

  useEffect(() => {
    loadScript("js/custom.js");
    loadScript("js/product-details.js");
  });

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    getProduct({ locale, productId })
      .then((res) => {
        setProduct(res.data.product);
        setImage(res.data.product.image);
        setProducts(res.data["related_products"]);
        setImages(res.data.product.images);
        setLoaded(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
    if (loaded) {
      dispatch(
        categoryFetch({
          locale,
          categoryId: product.categroy_id,
          productId: product.id,
        })
      );
    }
  }, [dispatch, productId]);

  useEffect(() => {
    if (product) {
      setDetails({
        kind: product.kind,
        engineCapacity: product.engineCapacity,
        suspensionType: product.suspensionType,
        mileAge: product.mileAge,
        year: product.year,
        configuration: product.configuration,
        gearBox: product.gearBox,
        axelsNumber: product.axelsNumber,
        number: product.number,
      });
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const currentpath = {
    crumb: loaded && product.title,
    title: loaded && product.title,
  };

  const notify = () => toast(t("added_to_cart"));

  const handleAddToCart = () => {
    dispatch(addToCart({ product, qty: count }));
    notify();
  };

  const increaseAmount = () => {
    setCount((prev) => prev + 1);
  };

  const decreaseAmount = () => {
    if (count <= 1) {
      setCount(1);
    } else {
      setCount((prev) => prev - 1);
    }
  };

  const handleNavigate = (itemId) => {
    setIsLoading(true);
    return navigate(`/${lang}/shop/detail/${itemId}/category/${categoryId}`);
  };

  const handleImageChange = (img) => {
    console.log({ img });
    setImage(img.url);
  };

  const displayImagesTop = () => {
    return (
      <div className="item">
        <div className="mfp-gallery">
          <div className="mt-box">
            <div className="mt-thum-bx mt-img-overlay1 ">
              <FastImage
                proImage={image}
                src={image}
                alt={loaded && product.title}
              />
              <div className="overlay-bx">
                <div className="overlay-icon">
                  <a className="mfp-link" href={image}>
                    <i className="fa fa-search mt-icon-box-xs" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const displayImagesBottom = () => {
    return (
      loaded &&
      images.map((image, i) => {
        return (
          <SwiperSlide onClick={() => handleImageChange(image)} key={image.id}>
            <div className="item">
              <div className="mt-media">
                <FastImage
                  proImage={image.url}
                  width={"100%"}
                  style={{
                    objectFit: "cover",
                  }}
                  alt="sub product"
                />
              </div>
            </div>
          </SwiperSlide>
        );
      })
    );
  };

  const generateProducts = () => {
    return products?.map((product, index) => {
      if (index < 2) {
        return (
          <div
            key={product.id}
            className="col-lg-4 col-md-6 col-xs-100pc m-b30"
          >
            <div className="mt-box mt-product-box bdr-1 bdr-solid bdr-gray-light">
              <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  proImage={product && image}
                  src={`${product && image}`}
                  alt={product.title}
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10">
                  <h4 className="mt-title">
                    <button
                      onClick={() => handleNavigate(product.id)}
                      style={{
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      {product.title}
                    </button>
                  </h4>
                  <span className="price">
                    <ins>
                      <span>
                        <span className="Price-currencySymbol">$ </span>
                        {product.price}
                      </span>
                    </ins>
                  </span>
                  <div className="p-tb15">
                    <button
                      onClick={() => handleNavigate(product.id)}
                      className="site-button"
                      type="button"
                    >
                      {t("show_more")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  const dir = lang === "en" ? "ltr" : "rtl";

  return (
    <Fragment>
      <HelmetComponent
        title={product?.title}
        description={product?.description}
        image={product?.image}
      />
      <SectionBanner data={currentpath} />
      <Toaster
        toastOptions={{
          style: {
            color: "#fff",
            background: "var(--color-main)",
          },
        }}
      />

      {/* Product Detail */}
      <div dir={dir} className="section-full p-t80 p-b50">
        <div className="container woo-entry">
          <div className="row m-b30">
            <div className="col-md-4 col-sm-4  m-b30">
              <div className="mt-box mt-product-gallery on-show-slider w-100">
                {/* main image display */}
                <SwiperWrapper loop>{displayImagesTop()}</SwiperWrapper>

                <div className="mt-4"></div>
                {/* sub images */}
                <SwiperWrapper
                  slidesPerView={4}
                  spaceBetween={30}
                  loop
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                >
                  {displayImagesBottom()}
                </SwiperWrapper>
              </div>
            </div>
            <div className="col-md-8 col-sm-8">
              <div className="mt-post-title ">
                <h3 className="post-title">{loaded && product.title}</h3>
              </div>
              <h2 className="m-tb10">${loaded && product.price} </h2>
              <div className="mt-post-text">
                <div
                  className="m-b10"
                  dangerouslySetInnerHTML={{
                    __html: loaded && product.description,
                  }}
                />
              </div>

              <div className="mt-post-text">
                {/* <ProductDescription data={details} /> */}
                <ul>
                  <li>
                    {t("kind")}: {product.kind}
                  </li>
                  <li>
                    {t("engine_capacity")}: {product.engineCapacity}
                  </li>
                  <li>
                    {t("suspension_type")}: {product.suspensionType}
                  </li>
                  <li>
                    {t("mile_age")}: {product.mileAge}
                  </li>
                  <li>
                    {t("configuration")}: {product.configuration}
                  </li>
                  <li>
                    {t("gear_box")}: {product.gearBox}
                  </li>
                  <li>
                    {t("axels_number")}: {product.axelsNumber}
                  </li>
                  <li>
                    {t("number")}: {product.number}
                  </li>
                  <li>
                    {t("year")}: {product.year}
                  </li>
                  <li>
                    {t("model")}: {product.model}
                  </li>
                  <li>
                    {t("engine")}: {product.engine}
                  </li>
                  <li>
                    {t("wheelbase")}: {product.wheelbase}
                  </li>
                  <li>
                    {t("cab")}: {product.cab}
                  </li>
                  <li>
                    {t("steering")}: {product.steering}
                  </li>
                  <li>
                    {t("rear_axles")}: {product.rear_axles}
                  </li>
                  <li>
                    {t("frame")}: {product.frame}
                  </li>
                  <li>
                    {t("gcw")}: {product.gcw}
                  </li>
                  <li>
                    {t("fuel_tanker")}: {product.fuel_tanker}
                  </li>
                  <li>
                    {t("tires")}: {product.tires}
                  </li>
                  <li>
                    {t("battery")}: {product.battery}
                  </li>
                  <li>
                    {t("oversize")}: {product.oversize}
                  </li>
                  <li>
                    {t("color")}: {product.color}
                  </li>
                  <li>
                    {t("transmission")}: {product.transmission}
                  </li>
                </ul>
              </div>

              <div className="m-b20 d-flex align-items-center justify-content-start gap-3">
                <span onClick={increaseAmount} style={{ cursor: "pointer" }}>
                  +
                </span>
                <input
                  // id="demo_vertical2"
                  type="text"
                  defaultValue={1}
                  // name="demo_vertical2"
                  value={count}
                  style={{ width: 50 }}
                />
                <span onClick={decreaseAmount} style={{ cursor: "pointer" }}>
                  -
                </span>
              </div>
              <button onClick={handleAddToCart} className="site-button">
                <i
                  className={`fa fa-cart-plus ${
                    lang === "en" ? "me-3" : "ms-3"
                  }`}
                />{" "}
                {t("add_to_cart")}
              </button>
            </div>
          </div>

          {/* TITLE START */}
          <div className="section-head  text-center">
            <h2 data-title={t("related_products")}>{t("related_products")}</h2>
            <div className="mt-separator-outer  m-b30">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END */}
          <div className="row">{generateProducts()}</div>
        </div>
      </div>
    </Fragment>
  );
}
export default ShopProductDetailPage;
